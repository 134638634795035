import React from "react"
import { domain, isServicesLandingPages } from "../utils";

const Media = ({ imageBorderRadiusForServices, media, width, columns, className, withWrapper, withCaption, captionPosition, withShadow , positionVideoCenter, addPaddingToVideoWrapper , shouldNotLazyload, showVideoAutoWith}) => {
  if (!media) { return <></> }
  if (!className) { className = ''; }
  if (!withWrapper) { withWrapper = true; }
  if (!withCaption) { withCaption = false; }
  if (!captionPosition) { captionPosition = 'left'; }
  if (!withShadow) { withShadow = true; }
  let mediaHtml;
  let colSize;
  const imageExts = ['.svg', '.png', '.gif', '.jpg', '.jpeg'];
  const isImage = imageExts.includes(media.ext);

  // Figure out column count...
  if (width === 'normal') {
    colSize = 'col-md-7 offset-md-2';
  } else if (width === 'medium') {
    colSize = 'col-10 offset-1';
  }
  else if (width==='semiMedium'){
    colSize = 'col-md-11 col-12';
  }
  else if (width === 'large') {
    colSize = 'col-12';
  } else if (width === 'byColumnCount') {
    colSize = 'col-sm-6 col-md-'+columns;
  }
  
  // Figure out media html
  if (isImage) {
    mediaHtml = (
      <img 
        style={{borderRadius: imageBorderRadiusForServices}} 
        className={'media '+className} 
        src={media.url} 
        alt={media.alternativeText}  
        {...(!shouldNotLazyload && { loading: 'lazy' })}
       />
    )
  } else {
    mediaHtml = (
      <div className={addPaddingToVideoWrapper ? "video--padding-lg" : ""} dangerouslySetInnerHTML={{ __html: `
        <video className="${('media '+className)}" style="${showVideoAutoWith ? "width: auto" : "width: 100%"}; ${imageBorderRadiusForServices && `border-radius: ${imageBorderRadiusForServices}`}" autoPlay muted loop playsinline>
          <source src="${(media.url)}" type="${(media.mime)}" />
        </video>
      ` }}
      />
    )
  }
  
  // Caption
  if ((width === 'byColumnCount' || withCaption) && media.caption) {
    mediaHtml = (
      <div className={'media-captioned media-captioned--'+captionPosition}>
        {mediaHtml}
        <div className="media-captioned__caption ">
          {media.caption}
        </div>
      </div>
    )
  }

  // Wrapping
  if (width === 'fullWidth') {
    mediaHtml = (
      <div className="container container--wide">
        {mediaHtml}
      </div>
    )
  } else if (width === 'byColumnCount' && columns) {
    mediaHtml = (
      <div className={colSize}>
        {mediaHtml}
      </div>
    );
  } else if (colSize) {
    mediaHtml = (
      <div className="container">
        <div className="row">
          <div className={positionVideoCenter ? "col-8  mx-auto" : `${colSize}`}>
            {mediaHtml}
          </div>
        </div>
      </div>
    );
  }

  return mediaHtml;
}

export default Media
