import React from "react"
import PropTypes from "prop-types"

import SiteHeader from "../site-header"

const SiteHeaderAlt = ({ header, children, serviceEmail,showWhiteIcon , redirectTo, anchorLinks, showX, isImage, showBlackNav}) => {
  return (
    <div className="main__body">
      {header && (
        <div className="site-header-alt-wrapper">
          <SiteHeader 
            header={header} 
            serviceEmail={serviceEmail} 
            showWhiteIcon={showWhiteIcon} 
            redirectTo={redirectTo && redirectTo} 
            anchorLinks={anchorLinks} 
            showX={showX} 
            isImage={isImage}
            showBlackNav={showBlackNav}
          />
        </div>
      )}
      {children}
    </div>
  )
}

SiteHeaderAlt.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SiteHeaderAlt
