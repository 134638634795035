import React, {useState} from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const MouseHover = ({children, caseStudy, classId, gethovered, borderRadius}) => {
  const [mousemovePosition, setMouseMovePosition] = useState({
    positionX: "-50px", 
    positionY: "-50px"
  })
  const breakpoints = useBreakpoint();

  const handleMouseMove = (event) => {
    if(breakpoints.sm) return; 
    const containerRect = event.currentTarget.getBoundingClientRect();
    const positionX = event.clientX - containerRect.left;
    const positionY = event.clientY - containerRect.top;
    setMouseMovePosition({
      positionX: `${positionX}px`,
      positionY: `${positionY}px`,
    });
  };

  const handleMouseEnter = (event) => {
    if(breakpoints.sm) return; 
    gethovered && gethovered('hovered')
    event.currentTarget.style.cursor = "none";
  };

  const handleMouseLeave = (event) => {
    if(breakpoints.sm) return; 
    event.currentTarget.style.cursor = "auto";
    gethovered && gethovered('')
    setMouseMovePosition(
      {
        positionX: "-50px", 
        positionY: "-50px"
      }
    )
  };

  return (
    <div 
      className={classId} style={{ backgroundColor: caseStudy && caseStudy.backgroundColor, borderRadius: borderRadius }}
      onMouseMove={(ev)=> handleMouseMove(ev)}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      {!breakpoints.sm && (
        <div 
          className={"mouse-hover__cursor"} 
          style={!breakpoints.sm ? { 
            left: mousemovePosition.positionX, 
            top: mousemovePosition.positionY , 
            cursor: breakpoints.sm ? "default" : "none" } : {}
          }
        >
        </div>
      )}
      {children}
    </div>
  )
}

export default MouseHover;