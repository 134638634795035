
import classNames from "classnames";
import React, { useState } from "react";
import { Fragment } from "react";
import Link from "./link";

const Tags = ({ tags, whiteTags, showLargeMargin, noMarginBottom , language, activeDisplay, borderRadius, hashtagsBackgroundColor, centerTags, increaseTagsMobileBottom }) => {
  const handleChildClick = (e) => {
    // This stops the click event from reaching the parent
    e.stopPropagation();
  }
  let isGermanPage;
  if (typeof window !== "undefined") {
    const location = window.location.pathname.indexOf("/de/") > -1;
    isGermanPage = location
  }

  return ( 
    <>
      {tags && tags.length > 0  && (
        <div className={classNames("tags", {"tags-margin--large": showLargeMargin, "tags-margin--large-mb": increaseTagsMobileBottom}, {"mb-0": noMarginBottom}, {"mt-0": activeDisplay, "d-flex justify-content-center": centerTags})} role="list">
          <div className="tags-inner tags-eclipse">
            {tags.map(({tag}, index) => (
              <Fragment key={index}>
                <Link
                  className={classNames( "tags-color", {"tags-color--white": whiteTags})}
                  to={`/${isGermanPage ? 'de' : 'en'}/tags/${tag}/`}
                  title={tag}
                  onClick={handleChildClick}
                  style={{ borderRadius: borderRadius, backgroundColor: hashtagsBackgroundColor }}
                >{`#${tag}`}</Link>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Tags;